<template>
  <transition>
    <div class="c-bottom-gradient" v-if="showGradient"></div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'

export default defineComponent({
  setup() {
    const showGradient = ref(true)
    const handleGradient = () => {
      window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 10
        ? (showGradient.value = false)
        : (showGradient.value = true)
    }

    onMounted(() => {
      window.addEventListener('scroll', handleGradient)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleGradient)
    })

    return {
      showGradient,
      handleGradient,
    }
  },
})
</script>
<style lang="scss">
.c-bottom-gradient {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: rem(90px);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0.6) 83%,
    rgba(255, 255, 255, 0) 100%
  );
  pointer-events: none;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}
</style>
