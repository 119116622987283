
import { defineComponent, onMounted, ref, computed } from 'vue'
import { useAssets, utils } from '@/core'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ProgressCard',

  props: {
    element: Object,
    order: Number,
    target: String,
    completed: Number,
    total: Number,
    progressType: String,
  },
  setup(props) {
    const coverURL = ref('')
    const { fetchAsset, getAssetById } = useAssets()
    const { addCloudinaryUrlParams } = utils
    const { t } = useI18n()
    const progressText = computed(() => {
      if (props.progressType === 'percent') {
        if (props.completed > 0)
          return t('PROGRESS_CARD_PERCENT', [props.completed])

        return t('PROGRESS_CARD_PERCENT_ZERO')
      }

      return t('PROGRESS_CARD_INFO', [props.completed, props.total])
    })
    onMounted(async () => {
      if (props.element.cover) {
        await fetchAsset(props.element.cover)
        const image = getAssetById(props.element.cover)
        coverURL.value = addCloudinaryUrlParams(image.url, 'h_100')
      }
    })
    return {
      coverURL,
      progressText,
    }
  },
})
