
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'

export default defineComponent({
  setup() {
    const showGradient = ref(true)
    const handleGradient = () => {
      window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 10
        ? (showGradient.value = false)
        : (showGradient.value = true)
    }

    onMounted(() => {
      window.addEventListener('scroll', handleGradient)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleGradient)
    })

    return {
      showGradient,
      handleGradient,
    }
  },
})
