<template>
  <Topbar :back-url="`/${currentCourse}/chapter-list`" back-icon="back" />
  <div class="v-chapter" v-if="chapter">
    <div class="v-chapter__info">
      <div
        class="v-chapter__info__image"
        :style="{ backgroundImage: `url(${coverURL})` }"
      ></div>
      <div class="v-chapter__info__title">
        {{ chapter.name }}
      </div>
      <div class="v-chapter__info__desc">
        {{ chapter.description }}
      </div>
    </div>
    <div class="v-chapter__lesson-list">
      <div v-for="(lesson, index) of chapter.childrens" :key="lesson.id">
        <LessonCard
          :element="lesson"
          target="lesson"
          :order="index + 1"
          progress-type="percent"
          :completed="Math.round(lessonProgress(lesson.id))"
          :total="100"
        />
      </div>
    </div>
    <BottomGradient />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue'
import { useHead } from '@vueuse/head'
import { useTenant } from '@/core'
import Topbar from '@/components/Topbar.vue'
import { useRoute } from 'vue-router'
import { useStructures, useAssets } from '@/core'
import LessonCard from '@/components/ProgressCard.vue'
import useCourseProgress from '@/composables/useCourseProgress'
import BottomGradient from '@/components/BottomGradient.vue'
import useCourseHandler from '@/composables/useCourseHandler'

export default defineComponent({
  components: {
    Topbar,
    LessonCard,
    BottomGradient,
  },
  setup() {
    const { tenant } = useTenant()
    const route = useRoute()
    const {
      fetchStructuresNode,
      fetchStructuresChildren,
      getStructuresBySlugPath,
    } = useStructures()
    const slug = Array.isArray(route.params.slugPath)
      ? route.params.slugPath.join('/')
      : route.params.slugPath
    const { fetchAsset, getAssetById } = useAssets()
    const { currentCourse } = useCourseHandler()
    const chapter = ref(null)
    const coverURL = ref('')
    const { getLessonQuestions } = useCourseProgress()
    const lessonProgress = (id: string) => {
      let progress = 0
      const lessonQuestionsBySlug = getLessonQuestions(id)

      if (lessonQuestionsBySlug) {
        if (lessonQuestionsBySlug.results.length > 0) {
          progress =
            (lessonQuestionsBySlug.results.reduce((pv, cv) => {
              const val = cv.answer ? 1 : 0
              return pv + val
            }, 0) /
              lessonQuestionsBySlug.results.length) *
            100
        } else {
          progress = 100
        }
      }

      return progress
    }

    useHead({
      title: computed(() => `${chapter.value?.name} - ${tenant.value.name}`),
    })

    onMounted(async () => {
      await fetchStructuresNode(slug)
      await fetchStructuresChildren(slug)
      chapter.value = getStructuresBySlugPath(slug, true)
      if (chapter.value.cover) {
        await fetchAsset(chapter.value.cover)
        const image = getAssetById(chapter.value.cover)
        coverURL.value = image.url
      }
    })

    return {
      chapter,
      coverURL,
      lessonProgress,
      currentCourse,
    }
  },
})
</script>
<style lang="scss">
.v-chapter {
  min-height: 100vh;
  padding: rem(16px);
  padding-top: rem($topbar-height);
  box-shadow: 0 -0.25rem 0.5rem $app-container-outline;

  &__info {
    padding: rem(16px);
    padding-top: rem(32px);

    &__image {
      height: rem(108px);
      background-size: cover;
      background-position: center;
    }

    &__title {
      margin-top: rem(16px);
      font-size: rem(32px);
      color: $course-card-title;
      font-weight: 700;
      padding: 0 rem(10px);
    }

    &__desc {
      margin-top: rem(8px);
      font-size: rem(16px);
      font-weight: 600;
      color: $course-card-desc;
      padding: 0 rem(10px);
    }
  }

  &__lesson-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
