<template>
  <router-link :to="`/${target}/${element.slugPath}`" class="c-card">
    <div
      v-if="element.cover"
      class="c-card__image"
      :style="{ backgroundImage: `url(${coverURL})` }"
    ></div>
    <div class="c-card__container">
      <div class="c-card__title">
        {{ order ? `${order}. ` : '' }}{{ element.name }}
      </div>
      <div v-if="element.description" class="c-card__desc">
        {{ element.description }}
      </div>
      <div
        class="c-card__progress"
        :class="[
          {
            'c-card__progress--not-started': completed === 0,
            'c-card__progress--success': completed === total,
          },
        ]"
      >
        {{ progressText }}
      </div>
    </div>
    <i class="vb-icon vb-icon-chevron-right" />
  </router-link>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue'
import { useAssets, utils } from '@/core'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ProgressCard',

  props: {
    element: Object,
    order: Number,
    target: String,
    completed: Number,
    total: Number,
    progressType: String,
  },
  setup(props) {
    const coverURL = ref('')
    const { fetchAsset, getAssetById } = useAssets()
    const { addCloudinaryUrlParams } = utils
    const { t } = useI18n()
    const progressText = computed(() => {
      if (props.progressType === 'percent') {
        if (props.completed > 0)
          return t('PROGRESS_CARD_PERCENT', [props.completed])

        return t('PROGRESS_CARD_PERCENT_ZERO')
      }

      return t('PROGRESS_CARD_INFO', [props.completed, props.total])
    })
    onMounted(async () => {
      if (props.element.cover) {
        await fetchAsset(props.element.cover)
        const image = getAssetById(props.element.cover)
        coverURL.value = addCloudinaryUrlParams(image.url, 'h_100')
      }
    })
    return {
      coverURL,
      progressText,
    }
  },
})
</script>

<style lang="scss">
.c-card {
  min-height: rem(48px);
  max-width: 100%;
  text-decoration: none;
  border-radius: 12px;
  color: $progress-card-text;
  box-shadow: 0 0 rem(16px) rem(9px) $results-content-shadow;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: rem(16px);
  padding-right: rem(8px);
  gap: rem(8px);

  @include bp-down(xsmall) {
    word-break: break-all;
  }

  &__container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: (8px);
    overflow: hidden;
  }
  &__image {
    min-width: rem(64px);
    min-height: rem(64px);
    border-radius: 12px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: rem(8px);
  }

  &__title {
    font-size: rem(20px);
    font-weight: 700;
    max-width: 100%;
  }
  &__desc {
    font-size: rem(16px);
    font-weight: 600;
    color: $progress-card-desc;
  }
  &__progress {
    font-size: rem(14px);
    line-height: rem(14px);
    font-weight: 500;
    &:before {
      content: '';
      display: inline-block;
      width: rem(6px);
      height: rem(6px);
      border-radius: rem(8px);
      margin-right: rem(10px);
      margin-left: rem(2px);
      border: 1px solid $progress-card-text;
      background-color: $progress-card-text;
    }
    &--success {
      &:before {
        border-color: $progress-card-success;
        background-color: $progress-card-success;
      }
    }
    &--not-started {
      &:before {
        background-color: transparent;
      }
    }
  }
  .vb-icon {
    font-size: rem(32px);
  }
}
</style>
