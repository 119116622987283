
import { defineComponent, onMounted, ref, computed } from 'vue'
import { useHead } from '@vueuse/head'
import { useTenant } from '@/core'
import Topbar from '@/components/Topbar.vue'
import { useRoute } from 'vue-router'
import { useStructures, useAssets } from '@/core'
import LessonCard from '@/components/ProgressCard.vue'
import useCourseProgress from '@/composables/useCourseProgress'
import BottomGradient from '@/components/BottomGradient.vue'
import useCourseHandler from '@/composables/useCourseHandler'

export default defineComponent({
  components: {
    Topbar,
    LessonCard,
    BottomGradient,
  },
  setup() {
    const { tenant } = useTenant()
    const route = useRoute()
    const {
      fetchStructuresNode,
      fetchStructuresChildren,
      getStructuresBySlugPath,
    } = useStructures()
    const slug = Array.isArray(route.params.slugPath)
      ? route.params.slugPath.join('/')
      : route.params.slugPath
    const { fetchAsset, getAssetById } = useAssets()
    const { currentCourse } = useCourseHandler()
    const chapter = ref(null)
    const coverURL = ref('')
    const { getLessonQuestions } = useCourseProgress()
    const lessonProgress = (id: string) => {
      let progress = 0
      const lessonQuestionsBySlug = getLessonQuestions(id)

      if (lessonQuestionsBySlug) {
        if (lessonQuestionsBySlug.results.length > 0) {
          progress =
            (lessonQuestionsBySlug.results.reduce((pv, cv) => {
              const val = cv.answer ? 1 : 0
              return pv + val
            }, 0) /
              lessonQuestionsBySlug.results.length) *
            100
        } else {
          progress = 100
        }
      }

      return progress
    }

    useHead({
      title: computed(() => `${chapter.value?.name} - ${tenant.value.name}`),
    })

    onMounted(async () => {
      await fetchStructuresNode(slug)
      await fetchStructuresChildren(slug)
      chapter.value = getStructuresBySlugPath(slug, true)
      if (chapter.value.cover) {
        await fetchAsset(chapter.value.cover)
        const image = getAssetById(chapter.value.cover)
        coverURL.value = image.url
      }
    })

    return {
      chapter,
      coverURL,
      lessonProgress,
      currentCourse,
    }
  },
})
